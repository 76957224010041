import React from 'react';
import { ReactNode } from 'react';

export const Badge = ({ children }: { children?: ReactNode }) => (
  <div className="ml-3 mt-1 flex rounded-lg bg-highlighted px-2 py-1">
    <span className="text-xs font-medium uppercase tracking-wider subpixel-antialiased">
      {children}
    </span>
  </div>
);
